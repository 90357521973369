<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand"><span>Developer tools</span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
        <a-menu-item>
          <span class="submenu-title-wrapper"><a-icon type="code" theme="filled" /> Base64</span>
        </a-menu-item>
				<a-menu-item>
					<router-link to="/base64encode">
						<span class="label">Base64 Encode</span>
					</router-link>
				</a-menu-item>
        <a-menu-item>
          <router-link to="/base64decode">
            <span class="label">Base64 Decode</span>
          </router-link>
        </a-menu-item>


        <a-menu-item>
          <span class="submenu-title-wrapper"><a-icon type="link" theme="outlined" /> URL</span>
        </a-menu-item>
        <a-menu-item>
          <router-link to="/url/encode">
            <span class="label">URL Encode</span>
          </router-link>
        </a-menu-item>

        <a-menu-item>
          <router-link to="/url/decode">
            <span class="label">URL Decode</span>
          </router-link>
        </a-menu-item>

			</a-menu>
			<!-- / Sidebar Navigation Menu -->
	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
				// sidebarCollapsedModel: this.sidebarCollapsed,
			}
		},
	})

</script>
